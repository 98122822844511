import React from 'react';
import playIcon from '../img/Aeroseal_icon_playbutton-01.svg';
import ModalVideo from 'react-modal-video';
import videoBg from '../img/AerosealBarrie_Video_BG.png';
import '../styles/Video.scss';

class Video extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}
	handleClick = () => {
		this.setState(() => ({ isOpen: true }));
	};
	render() {
		return (
			<div className="Video">
				<section
					className="is-inverted is-skewed videoSection"
					style={{
						backgroundImage: `url(${videoBg})`,
					}}
				>
					<div className="section has-text-centered is-medium">
						<img
							src={playIcon}
							style={{ width: '6rem', cursor: 'pointer' }}
							alt="play button"
							onClick={this.handleClick}
						/>
						<h2>
							How Aeroseal works
							<br />
							for homes
						</h2>
					</div>
				</section>
				<ModalVideo
					channel="youtube"
					isOpen={this.state.isOpen}
					videoId="3XkvJ4qGSqI"
					onClose={() => this.setState({ isOpen: false })}
				/>
			</div>
		);
	}
}

export default Video;
